define(['app', 'elementViewed', 'ePopup', 'siteObj'], function (app, elementViewed, ePopup, siteObj) {
  
  var papBanner = function () {
    var component = {};
    component.elementViewed = elementViewed;
    
    var _config = {
      selectors: {
        popupLink: '[data-js-element=popupLink]',
        popupBanner: '[data-js-element=papPopup]',
        papPopupContents: '[data-papPopup-contents]',
        readMoreTranslation: '[data-read-more-translation]',
      },
      attributes: {
        dataReadMoreTranslation: 'data-read-more-translation',
        ariaLabel: 'aria-label'
      },
      classes: {
        papBannerPopup: 'papBanner_popup'
      },
    };
    
    var _init = function (element) {
      component.element = element;
      component.popupLink = component.element.querySelector(component.config.selectors.popupLink);
      component.popupBanner = component.element.querySelector(component.config.selectors.popupBanner);
      component.papPopupContents = component.element.querySelector(component.config.selectors.papPopupContents);
      
      const readMoreTranslation = component.element.querySelector(component.config.selectors.readMoreTranslation).getAttribute(component.config.attributes.dataReadMoreTranslation);
      const ariaLabel = component.popupLink.innerText + readMoreTranslation;
      component.popupLink.setAttribute(component.config.attributes.ariaLabel, ariaLabel);
      
      component.bind();

      if(siteObj.config.enableQubit === true) {
        siteObj.methods = siteObj.methods || {};
        siteObj.methods.qubitPapBanner = component.loadPapBanner;
      }

      return component;
    };
    
    var _bind = function () {
      
      component.popupLink.addEventListener('click', component.papBannerClicked);
      component.elementViewed(component.element, component.papBannerViewed);
    };
    
    var _papBannerClicked = function (e) {
      if (e) {
        e.preventDefault();
      }
      component.loadPapBanner(component.papPopupContents);
    };

    var _loadPapBanner = function (papPopupContents) {
      component.ePopup = new ePopup(papPopupContents, component.config.classes.papBannerPopup, false, true);
      app.publish('tracking/record', 'Pap | papBanner', 'Clicked', 'papBanner component');
    };

    var _papBannerViewed = function () {
      let eventLabelValue = '';

      eventLabelValue = component.papBannerDetails(component.element);

      app.publish('tracking/record', 'Pap | papBanner', 'Viewed', 'papBanner component', eventLabelValue);


      if(siteObj.config.useGa4EnhancedEcom === true) {
        component.GA4BannerViewed(component.element, eventLabelValue);
      }

    };

    var _GA4BannerViewed = function (element, eventLabelValue) {
      /*
      GA4 Tracking
      event,
      eventName,
      currencyCode,
      data
      */
      const productItem = component.papProductItemDetails(element, eventLabelValue);
      const dataLayerValues = {
        event: 'ecom_event',
        event_name: 'view_promotion',
        ecommerce: {
          promotion_name: eventLabelValue,
          creative_slot: 'papBanner Component',
          promotion_id: null,
          item: productItem
        }
      }
      app.publish('record/general', dataLayerValues);
    }

    var _papBannerDetails = function (element) {
      const papBanner = element.querySelector('.papBanner_text');
      const papTitle = 'innerText' in papBanner ?  papBanner.innerText : '';
      return papTitle
    }

    var _papProductItemDetails = function(element, eventLabelValue) {

      const productBlock = element.closest('.productBlock');

      let productItemData;

      if (productBlock !== null && productBlock !== 'undefined') {
        if(productBlock.dataset) {
          const item = productBlock.dataset;
          productItemData = {
            item_id: item.productId,
            item_external_id: item.externalSku,
            item_name: item.productName,
            item_brand: item.productBrand,
            price: item.price,
            item_category: item.category,
            item_currency: siteObj.currencyType,
            index: item.productPosition ? item.productPosition : '',
            item_page: window.location.pathname,
            item_promotion: eventLabelValue
          }
        }
        return productItemData;
      }
    }

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.papBannerClicked = _papBannerClicked;
    component.papBannerViewed = _papBannerViewed;
    component.loadPapBanner = _loadPapBanner;
    component.papBannerDetails = _papBannerDetails;
    component.papProductItemDetails = _papProductItemDetails;
    component.GA4BannerViewed = _GA4BannerViewed;
    return component;
  };
  
  return papBanner;
});
